.cover-change-start {
    background-color: #164e8e;

    .step-numbers {
        .step-number {
            display: inline-block;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            background-color: #043062;
            color: #b4c1d0;
            line-height: 36px;
            font-size: 15px;
            font-weight: bold;
            margin: 0 5px;
            &.active {
                background-color: #67c8c6;
                color: #09366a;
            }
            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }
        }
    }

    .answers-section {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .answer {
            display: inline-block;
            padding: 15px;
            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }
            .answer-circle {
                position: relative;
                display: inline-block;
                background-color: #dafdfc;
                width: 200px;
                height: 200px;
                border-radius: 50%;
                line-height: 200px;
                .check-circle {
                    display: none;
                }
            }
            .title {
                display: block;
                padding-top: 15px;
                color: #ffffff;
                font-size: 24px;
                font-weight: bold;
                line-height: 28px;
                max-width: 220px;
            }
            .subtitle {
                padding-top: 5px;
                display: block;
                font-size: 16px;
                line-height: 20px;
                max-width: 220px;
                &-blue {
                    color: #85e8e6;
                }
                &-white {
                    color: #ffffff;
                }
            }
            &.active {
                .answer-circle {
                    box-shadow: inset 0px 0px 0px 8px #a9d500;
                    background-color: #ffffff;
                    .check-circle {
                        display: inline-block;
                        position: absolute;
                        top: 0px;
                        right: 12px;
                        background-color: #a9d500;
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        svg {
                            color: #ffffff;
                            width: 30px;
                            height: 30px;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
}

.cover-change-choose {
    .products-holder {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        display: flex;
        align-items: stretch;
        .offer {
            display: inline-block;
            padding: 0px 10px 0px 10px;
            vertical-align: top;
            position: relative;
            .font-grey {
                color: #9998a8;
            }
            .font-cyan {
                color: #67c8c6;
            }
            &.recommended {
                .recommended-tab {
                    background: #a9d500;
                    display: inline-block !important;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: bold;
                    padding-top: 5px;
                    position: absolute;
                    width: 146px;
                    height: 26px;
                    text-align: center;
                    top: -26px;
                    left: 50%;
                    margin-left: -73px;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
                .inner {
                    box-shadow: 0px 0px 0px 6px rgba(169, 213, 0, 1);
                }
            }
            &.selected {
                .selected-tab {
                    background: #a1b1c3;
                    display: inline-block !important;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: bold;
                    padding-top: 5px;
                    position: absolute;
                    width: 146px;
                    height: 26px;
                    text-align: center;
                    top: -26px;
                    left: 50%;
                    margin-left: -73px;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    i {
                        position: relative;
                        top: 2px;
                        font-size: 14px;
                        line-height: 0px;
                    }
                }
                .inner {
                    box-shadow: 0px 0px 0px 6px rgba(161, 177, 195, 1);
                }
            }
            .inner {
                white-space: initial;
                text-align: left;
                width: 370px;
                position: relative;
                // padding: 30px;
                background-color: #ffffff;
                border-radius: 6px;
                height: 100%;
                @media (max-width: 375px) {
                    width: 285px;
                }
                .innerpadding {
                    padding: 0px 20px;
                    .matchingHeight {
                        min-height: 220px;
                    }
                    @media (max-width: 767px) {
                        padding: 0px 10px 0px 10px !important;
                    }
                    .discount {
                        min-height: 20px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 10px;
                        width: 80%;
                        div {
                            background-color: #164e8e;
                            color: #ffffff;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            font-size: 11px;
                            font-weight: bold;
                        }
                    }
                    .mobile-left {
                        padding-left: 0;
                        padding-right: 0;
                        @media (max-width: 767px) {
                        }
                        .type,
                        .hospital-type,
                        .extras-type {
                            font-size: 12px;
                            color: #9998a8;
                            font-weight: 500;
                            @media (max-width: 767px) {
                                font-size: 10px;
                            }
                        }
                        .title {
                            font-size: 24px;
                            color: #67c8c6;
                            font-weight: bold;
                            @media (max-width: 767px) {
                                font-size: 16px;
                                word-wrap: normal;
                            }
                            @media all and (-ms-high-contrast: none) {
                                &,
                                *::-ms-backdrop {
                                    white-space: pre-wrap;
                                }
                            }
                        }
                        .excess-holder {
                            & > span {
                                font-size: 14px;
                                font-weight: 500;
                            }
                        }
                        .excess {
                            color: #333333;
                            .excess-select {
                                select {
                                    border: 0;
                                    appearance: none;
                                    color: #1d1c2d;
                                    padding-right: 10px;
                                    background: transparent;
                                    font-weight: bold;
                                }
                                // &:after {
                                //     color: #1D1C2D;
                                //     // content: "d";
                                //     // font-family: "union-health";
                                //     display: inline-block;
                                //     font-size: 8px;
                                //     margin-left: -8px;
                                //     pointer-events: none;
                                // }
                                .dropArrow {
                                    pointer-events: none;
                                    display: inline-block;
                                    margin-left: -10px;
                                }
                            }
                        }
                    }
                    .mobile-right {
                        padding-left: 0;
                        padding-right: 0;
                        @media (max-width: 767px) {
                        }
                        .calc_package-price {
                            font-weight: bold;
                            color: #164e8e;
                            font-size: 34px;
                            @media (max-width: 767px) {
                                font-size: 20px !important;
                                line-height: 28px !important;
                                margin-top: 0px !important;
                            }
                        }
                        .calc_package-payment-frequency {
                            span {
                                position: relative;
                                select {
                                    appearance: none;
                                    background: transparent;
                                    border: 0;
                                    padding-right: 20px;
                                    font-family: 'Lato';
                                    font-size: 12px;
                                    color: #1d1c2d;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    &:focus {
                                        outline: none;
                                    }
                                    &::-ms-expand {
                                        display: none;
                                    }
                                }
                                // &:after{
                                //     display: inline-block;
                                //     // font-family: 'union-health';
                                //     // content: "d";
                                //     font-size: 12px;
                                //     margin-left: -20px;
                                //     pointer-events: none;
                                // }
                                .dropArrow {
                                    pointer-events: none;
                                    display: inline-block;
                                    margin-left: -20px;
                                }
                            }
                        }
                    }
                    .rounded-shadow {
                        height: 10px;
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                    .buttons {
                        a {
                            width: 100%;
                            i {
                                position: relative;
                                top: 2px;
                            }
                            &.btn {
                                border-radius: 6px;
                                text-align: center;
                                font-size: 14px;
                                letter-spacing: 1px;
                                color: #333;
                                background-color: #67c8c6;
                                display: inline-block;
                                font-weight: 600;
                                font-family: 'Lato', helvetica;
                                padding: 12px 20px;
                                margin: 15px 0px;
                                transition: 300ms linear;
                            }
                            &.btn--light-grey {
                                border: 2px solid #efeff5;
                                background-color: #efeff5 !important;
                                color: #363636;
                                padding: 12px 10px;
                                @media (min-width: 768px) {
                                    display: inline-block;
                                }
                            }
                            &.btn-select {
                                border: 2px solid #8986ca;
                                background-color: #8986ca !important;
                                color: #ffffff;
                            }
                        }
                        @media (max-width: 767px) {
                            div {
                                padding: 0px 5px !important;
                            }
                            a {
                                font-size: 12px;
                                padding: 10px 8px;
                                &.btn-add-to-quote {
                                    padding: 8px 8px 9px 8px;
                                }
                            }
                        }
                    }
                }
                .bgcolor-darkblue {
                    background-color: #164e8e;
                    color: #ffffff;
                    padding: 10px 30px;
                    font-weight: bold;
                }
                .tabs {
                    // padding-top: 10px;
                    padding-bottom: 10px;
                    .nav-tabs {
                        border: 0;
                        background-color: #cfd9e4;
                        margin-bottom: 0;
                        display: block;
                        list-style: none;
                        li {
                            display: block;
                            float: left;
                            margin-top: 2px;
                            margin-bottom: 0;
                            width: 50%;
                            text-align: center;
                            a {
                                display: block;
                                padding: 10px 15px;
                                margin-right: 0;
                                border: 0;
                                border-radius: 0;
                                background-color: #cfd9e4;
                                color: #134580;
                                text-decoration: none;
                                span {
                                    display: inline-block;
                                    height: 16px;
                                    width: 16px;
                                    background-color: #134580;
                                    border-radius: 50%;
                                    position: relative;
                                    top: 3px;
                                }
                            }
                            &.active {
                                a {
                                    background-color: #f1f5fa;
                                    span {
                                        border: 5px solid #134580;
                                        background-color: #67c8c6;
                                    }
                                }
                            }
                        }
                        &:after,
                        &:before {
                            content: ' ';
                            display: table;
                            clear: both;
                        }
                    }
                    .tab-pane {
                        &#hospital {
                            .col-headers {
                                margin-bottom: 10px;
                            }
                        }
                        &#extras {
                            .col-headers {
                                background-color: #f1f5fa;
                                font-family: 'Lato';
                                font-weight: 500;
                                font-size: 14px;
                                color: #134580;
                                display: flex;
                                padding: 10px 30px;
                                span {
                                    display: inline-block;
                                    flex-grow: 1;
                                    &.left {
                                    }
                                    &.right {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                }
                .cover-items {
                    padding: 10px 0px 40px 0px;
                    @media (max-width: 767px) {
                        padding-bottom: 40px !important;
                    }
                    .product-highlights {
                        // padding: 0px 30px;
                        .item {
                            padding: 5px 30px;
                            color: #333333 !important;
                            .title {
                                width: 50%;
                                display: inline-block;
                                float: left;
                                font-size: 13px;
                                i {
                                    position: relative;
                                    top: 2px;
                                    color: #67c8c6;
                                    padding-right: 5px;
                                }
                            }
                            .value {
                                width: 50%;
                                display: inline-block;
                                float: right;
                                text-align: right;
                            }
                            &:after {
                                display: table;
                                content: '';
                                clear: both;
                            }
                            &.extras_cover {
                                .title {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                    .item {
                        display: flex;
                        position: relative;
                        padding: 5px 30px 5px 30px;
                        color: #333333 !important;
                        font-size: 13px;
                        i {
                            position: relative;
                            // top: 2px;
                            color: #67c8c6;
                            padding-right: 10px;
                            &.icon-info {
                                cursor: pointer;
                                color: #8986ca;
                            }
                        }
                        span {
                            font-size: 13px;
                        }
                        &.extras_cover {
                            justify-content: space-between;
                            height: 28px;
                            & > span:first-child {
                                position: relative;
                                padding-left: 23px;
                                i {
                                    position: absolute;
                                    left: 0;
                                }
                                @media (max-width: 767px) {
                                    line-height: 13px;
                                }
                            }
                            .right-side {
                                text-align: right;
                            }
                            &.parent {
                                & > span:first-child {
                                    padding-left: 0;
                                }
                            }
                            &.secondlevel {
                                border-bottom: 1px solid rgba(216, 216, 216, 1);
                                padding-left: 0px;
                                margin-left: 30px;
                                & > span:first-child {
                                    padding-left: 0px;
                                    font-size: 11px;
                                    color: #67c8c6;
                                    font-weight: 800;
                                    text-transform: uppercase;
                                }
                            }
                        }
                        &:not(.extras_cover) {
                            span {
                                padding-left: 23px;
                                position: relative;
                                @media all and (-ms-high-contrast: none) {
                                    &,
                                    *::-ms-backdrop {
                                        white-space: pre-wrap;
                                    }
                                }
                            }
                            i {
                                // position: absolute;
                                // left: 0;
                                svg {
                                    height: 18px;
                                    width: 18px;
                                    margin-right: -5px;
                                    margin-left: -3px;
                                }
                            }
                        }
                        .right-side {
                            display: inline-block;
                            float: right;
                            .icon-info {
                                position: absolute;
                                background-color: #ffffff;
                                top: 5px;
                                right: 9px;
                                z-index: 1;
                                line-height: 1;
                                border: 1px solid #ffffff;
                                border-radius: 50%;
                                padding: 0;
                            }
                        }
                        &.excluded {
                            background-color: #efeff5;
                            i {
                                color: #9998a8;
                            }
                            span {
                                color: #9998a8;
                            }
                        }
                        &.parent {
                            padding: 5px 30px;
                            box-shadow: 0 -1px 0 0 rgba(216, 216, 216, 1),
                                0 1px 0 0 rgba(216, 216, 216, 1);
                            i {
                                padding-right: 0px;
                            }
                            span {
                                // padding-left: 0px;
                                font-weight: 800;
                            }
                        }
                        &.group {
                            padding: 5px 30px 5px 30px;
                            &:after {
                                content: '';
                                position: absolute;
                                top: 5px;
                                bottom: -5px;
                                right: 15px;
                                display: inline-block;
                                background-color: rgba(137, 134, 202, 0.5);
                                width: 2px;
                            }
                            .right-side {
                            }
                            &.last-group {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                        &:first-child {
                            &.parent {
                                box-shadow: 0 1px 0 0 rgba(216, 216, 216, 1);
                            }
                        }
                        &.parent + .parent {
                            box-shadow: 0 1px 0 0 rgba(216, 216, 216, 1);
                        }
                    }
                    .collapse-btns {
                        margin-top: 10px;
                        width: 100%;
                        position: absolute;
                        bottom: 10px;
                        text-align: center;
                        button {
                            margin: 0 auto;
                            background-color: #a1bcdb;
                            font-weight: bold;
                            font-size: 12px;
                            padding: 2px 18px;
                            border-radius: 2px;
                        }
                        // .hider{
                        //     display: none;
                        // }
                        i {
                            position: relative;
                            top: 2px;
                        }
                    }
                    // .collapse.in + .collapse-btns{
                    //     .shower{
                    //         display: none;
                    //     }
                    //     .hider{
                    //         display: inline-block;
                    //     }
                    // }
                }
            }
        }
    }
}

.cover-change-quote {
    .page-title {
        font-weight: 900;
        font-size: 46px;
        margin-bottom: 40px;
    }
    .left-box {
        padding-left: 0px;
        .inner {
            display: flex;
            height: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
            .hospital-side {
                flex: 1;
                text-align: center;
                padding: 15px;
                display: flex;
                &.no {
                    .icon {
                        img {
                            filter: grayscale(100%);
                        }
                    }
                    .product-title {
                        color: #a1b5cd;
                    }
                }
            }
            .extras-side {
                flex: 1;
                text-align: center;
                box-shadow: inset 1px 0px 0px 0px rgba(0, 0, 0, 0.15);
                padding: 15px;
                display: flex;
                &.no {
                    .icon {
                        img {
                            filter: grayscale(100%);
                        }
                    }
                    .product-title {
                        color: #a1b5cd;
                    }
                }
            }
            .bundle-side {
                flex: 1;
                text-align: center;
                padding: 15px;
                display: flex;
            }
            .inner-flex {
                align-self: top;
                width: 100%;
            }
            .icon {
                margin-bottom: 10px;
                img {
                    width: 50px;
                }
            }
            .product-type {
                color: #838a8e;
                font-size: 11px;
                font-weight: 500;
                text-transform: uppercase;
            }
            .product-title {
                color: #67c8c6;
                font-size: 20px;
                font-weight: bold;
            }
            .product-excess {
                .excess-text {
                    color: #838a8e;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                .excess-amount {
                    color: #1d1c2d;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
    }
    .right-box {
        padding-right: 0px;
        .inner {
            display: flex;
            height: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
            .family-side {
                flex: 1;
                text-align: center;
                padding: 15px;
                height: 100%;
                display: flex;
                .inner-flex {
                    align-self: center;
                    width: 100%;
                }
                .icon-holder {
                    margin-bottom: 10px;
                    img {
                        height: 35px;
                    }
                }
                .text-holder {
                    color: #134580;
                    font-size: 18px;
                }
                .family-type-select-holder {
                    color: #8986ca;
                    font-size: 20px;
                    line-height: 29px;
                    font-weight: bold;
                }
            }
            .quote-side {
                flex: 1;
                text-align: center;
                padding: 15px;
                box-shadow: inset 1px 0px 0px 0px rgba(0, 0, 0, 0.15);
                height: 100%;
                display: flex;
                .inner-flex {
                    align-self: center;
                    width: 100%;
                }
                .top-holder {
                    font-size: 12px;
                    font-weight: 500;
                    color: #838a8e;
                    text-transform: uppercase;
                    line-height: 20px;
                    margin-bottom: 10px;
                }
                .price-holder {
                    color: #164e8e;
                    font-size: 34px;
                    font-weight: 700;
                    line-height: 28px;
                }
                .payment-select-holder {
                    margin-bottom: 10px;
                    span {
                        position: relative;
                        select {
                            appearance: none;
                            background: transparent;
                            border: 0;
                            padding-right: 20px;
                            font-family: 'Lato';
                            font-size: 12px;
                            color: #1d1c2d;
                            font-weight: 500;
                            text-transform: uppercase;
                            &:focus {
                                outline: none;
                            }
                            &::-ms-expand {
                                display: none;
                            }
                        }
                        // &:after{
                        //     display: inline-block;
                        //     // font-family: 'union-health';
                        //     // content: "d";
                        //     font-size: 12px;
                        //     margin-left: -20px;
                        //     pointer-events: none;
                        // }
                        .dropArrow {
                            pointer-events: none;
                            display: inline-block;
                            margin-left: -20px;
                        }
                    }
                }
                .button-holder {
                    button {
                        width: 100%;
                    }
                }
            }
        }
    }

    .selected-products-holder {
        padding: 30px 0px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        display: flex;
        align-items: stretch;
        justify-content: center;
        .offer {
            display: inline-block;
            padding: 0px 10px 0px 10px;
            vertical-align: top;
            position: relative;
            .font-grey {
                color: #9998a8;
            }
            .font-cyan {
                color: #67c8c6;
            }
            &.recommended {
                .recommended-tab {
                    background: #a9d500;
                    display: inline-block !important;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: bold;
                    padding-top: 5px;
                    position: absolute;
                    width: 146px;
                    height: 26px;
                    text-align: center;
                    top: -26px;
                    left: 50%;
                    margin-left: -73px;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
                .inner {
                    box-shadow: 0px 0px 0px 6px rgba(169, 213, 0, 1);
                }
            }
            &.selected {
                .selected-tab {
                    background: #a1b1c3;
                    display: inline-block !important;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: bold;
                    padding-top: 5px;
                    position: absolute;
                    width: 146px;
                    height: 26px;
                    text-align: center;
                    top: -26px;
                    left: 50%;
                    margin-left: -73px;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    i {
                        position: relative;
                        top: 2px;
                        font-size: 14px;
                        line-height: 0px;
                    }
                }
                .inner {
                    box-shadow: 0px 0px 0px 6px rgba(161, 177, 195, 1);
                }
            }
            .inner {
                white-space: initial;
                text-align: left;
                width: 370px;
                position: relative;
                // padding: 30px;
                background-color: #ffffff;
                border-radius: 6px;
                height: 100%;
                @media (max-width: 375px) {
                    width: 285px;
                }
                .innerpadding {
                    padding: 0px 20px;
                    .matchingHeight {
                        min-height: 260px;
                    }
                    @media (max-width: 767px) {
                        padding: 0px 10px 0px 10px !important;
                    }
                    .discount {
                        min-height: 20px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 10px;
                        width: 80%;
                        div {
                            background-color: #164e8e;
                            color: #ffffff;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                            font-size: 11px;
                            font-weight: bold;
                        }
                    }
                    .mobile-left {
                        padding-left: 0;
                        padding-right: 0;
                        @media (max-width: 767px) {
                        }
                        .type,
                        .hospital-type,
                        .extras-type {
                            font-size: 12px;
                            color: #9998a8;
                            font-weight: 500;
                            @media (max-width: 767px) {
                                font-size: 10px;
                            }
                        }
                        .title {
                            font-size: 24px;
                            color: #67c8c6;
                            font-weight: bold;
                            @media (max-width: 767px) {
                                font-size: 16px;
                                word-wrap: normal;
                            }
                            @media all and (-ms-high-contrast: none) {
                                &,
                                *::-ms-backdrop {
                                    white-space: pre-wrap;
                                }
                            }
                        }
                        .excess-holder {
                            & > span {
                                font-size: 14px;
                                font-weight: 500;
                            }
                        }
                        .excess {
                            color: #333333;
                            .excess-select {
                                select {
                                    border: 0;
                                    appearance: none;
                                    color: #1d1c2d;
                                    padding-right: 10px;
                                    background: transparent;
                                    font-weight: bold;
                                }
                                // &:after {
                                //     color: #1D1C2D;
                                //     // content: "d";
                                //     // font-family: "union-health";
                                //     display: inline-block;
                                //     font-size: 8px;
                                //     margin-left: -8px;
                                //     pointer-events: none;
                                // }
                                .dropArrow {
                                    pointer-events: none;
                                    display: inline-block;
                                    margin-left: -10px;
                                }
                            }
                        }
                    }
                    .mobile-right {
                        padding-left: 0;
                        padding-right: 0;
                        @media (max-width: 767px) {
                        }
                        .calc_package-price {
                            font-weight: bold;
                            color: #164e8e;
                            font-size: 34px;
                            @media (max-width: 767px) {
                                font-size: 20px !important;
                                line-height: 28px !important;
                                margin-top: 0px !important;
                            }
                        }
                        .calc_package-payment-frequency {
                            span {
                                position: relative;
                                select {
                                    appearance: none;
                                    background: transparent;
                                    border: 0;
                                    padding-right: 20px;
                                    font-family: 'Lato';
                                    font-size: 12px;
                                    color: #1d1c2d;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    &:focus {
                                        outline: none;
                                    }
                                    &::-ms-expand {
                                        display: none;
                                    }
                                }
                                // &:after{
                                //     display: inline-block;
                                //     // font-family: 'union-health';
                                //     // content: "d";
                                //     font-size: 12px;
                                //     margin-left: -20px;
                                //     pointer-events: none;
                                // }
                                .dropArrow {
                                    pointer-events: none;
                                    display: inline-block;
                                    margin-left: -20px;
                                }
                            }
                        }
                    }
                    .rounded-shadow {
                        height: 10px;
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                    .buttons {
                        a {
                            width: 100%;
                            i {
                                position: relative;
                                top: 2px;
                            }
                            &.btn {
                                border-radius: 6px;
                                text-align: center;
                                font-size: 14px;
                                letter-spacing: 1px;
                                color: #333;
                                background-color: #67c8c6;
                                display: inline-block;
                                font-weight: 600;
                                font-family: 'Lato', helvetica;
                                padding: 12px 20px;
                                margin: 15px 0px;
                                transition: 300ms linear;
                            }
                            &.btn--light-grey {
                                border: 2px solid #efeff5;
                                background-color: #efeff5 !important;
                                color: #363636;
                                padding: 12px 10px;
                                @media (min-width: 768px) {
                                    display: inline-block;
                                }
                            }
                            &.btn-select {
                                border: 2px solid #8986ca;
                                background-color: #8986ca !important;
                                color: #ffffff;
                            }
                        }
                        @media (max-width: 767px) {
                            div {
                                padding: 0px 5px !important;
                            }
                            a {
                                font-size: 12px;
                                padding: 10px 8px;
                                &.btn-add-to-quote {
                                    padding: 8px 8px 9px 8px;
                                }
                            }
                        }
                    }
                }
                .bgcolor-darkblue {
                    background-color: #164e8e;
                    color: #ffffff;
                    padding: 10px 30px;
                    font-weight: bold;
                }
                .tabs {
                    // padding-top: 10px;
                    padding-bottom: 10px;
                    .nav-tabs {
                        border: 0;
                        background-color: #cfd9e4;
                        margin-bottom: 0;
                        display: block;
                        list-style: none;
                        li {
                            display: block;
                            float: left;
                            margin-top: 2px;
                            margin-bottom: 0;
                            width: 50%;
                            text-align: center;
                            a {
                                display: block;
                                padding: 10px 15px;
                                margin-right: 0;
                                border: 0;
                                border-radius: 0;
                                background-color: #cfd9e4;
                                color: #134580;
                                text-decoration: none;
                                span {
                                    display: inline-block;
                                    height: 16px;
                                    width: 16px;
                                    background-color: #134580;
                                    border-radius: 50%;
                                    position: relative;
                                    top: 3px;
                                }
                            }
                            &.active {
                                a {
                                    background-color: #f1f5fa;
                                    span {
                                        border: 5px solid #134580;
                                        background-color: #67c8c6;
                                    }
                                }
                            }
                        }
                        &:after,
                        &:before {
                            content: ' ';
                            display: table;
                            clear: both;
                        }
                    }
                    .tab-pane {
                        &#hospital {
                            .col-headers {
                                margin-bottom: 10px;
                            }
                        }
                        &#extras {
                            .col-headers {
                                background-color: #f1f5fa;
                                font-family: 'Lato';
                                font-weight: 500;
                                font-size: 14px;
                                color: #134580;
                                display: flex;
                                padding: 10px 30px;
                                span {
                                    display: inline-block;
                                    flex-grow: 1;
                                    &.left {
                                    }
                                    &.right {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                }
                .cover-items {
                    padding: 10px 0px 40px 0px;
                    @media (max-width: 767px) {
                        padding-bottom: 40px !important;
                    }
                    .product-highlights {
                        // padding: 0px 30px;
                        .item {
                            padding: 5px 30px;
                            color: #333333 !important;
                            .title {
                                width: 50%;
                                display: inline-block;
                                float: left;
                                font-size: 13px;
                                i {
                                    position: relative;
                                    top: 2px;
                                    color: #67c8c6;
                                    padding-right: 5px;
                                }
                            }
                            .value {
                                width: 50%;
                                display: inline-block;
                                float: right;
                                text-align: right;
                            }
                            &:after {
                                display: table;
                                content: '';
                                clear: both;
                            }
                            &.extras_cover {
                                .title {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                    .item {
                        display: flex;
                        position: relative;
                        padding: 5px 30px 5px 30px;
                        color: #333333 !important;
                        font-size: 13px;
                        i {
                            position: relative;
                            // top: 2px;
                            color: #67c8c6;
                            padding-right: 10px;
                            &.icon-info {
                                cursor: pointer;
                                color: #8986ca;
                            }
                        }
                        span {
                            font-size: 13px;
                        }
                        &.extras_cover {
                            justify-content: space-between;
                            height: 28px;
                            & > span:first-child {
                                position: relative;
                                padding-left: 23px;
                                i {
                                    position: absolute;
                                    left: 0;
                                }
                                @media (max-width: 767px) {
                                    line-height: 13px;
                                }
                            }
                            .right-side {
                                text-align: right;
                            }
                            &.parent {
                                & > span:first-child {
                                    padding-left: 0;
                                }
                            }
                            &.secondlevel {
                                border-bottom: 1px solid rgba(216, 216, 216, 1);
                                padding-left: 0px;
                                margin-left: 30px;
                                & > span:first-child {
                                    padding-left: 0px;
                                    font-size: 11px;
                                    color: #67c8c6;
                                    font-weight: 800;
                                    text-transform: uppercase;
                                }
                            }
                        }
                        &:not(.extras_cover) {
                            span {
                                padding-left: 23px;
                                position: relative;
                                @media all and (-ms-high-contrast: none) {
                                    &,
                                    *::-ms-backdrop {
                                        white-space: pre-wrap;
                                    }
                                }
                            }
                            i {
                                // position: absolute;
                                // left: 0;
                                svg {
                                    height: 18px;
                                    width: 18px;
                                    margin-right: -5px;
                                    margin-left: -3px;
                                }
                            }
                        }
                        .right-side {
                            display: inline-block;
                            float: right;
                            .icon-info {
                                position: absolute;
                                background-color: #ffffff;
                                top: 5px;
                                right: 9px;
                                z-index: 1;
                                line-height: 1;
                                border: 1px solid #ffffff;
                                border-radius: 50%;
                                padding: 0;
                            }
                        }
                        &.excluded {
                            background-color: #efeff5;
                            i {
                                color: #9998a8;
                            }
                            span {
                                color: #9998a8;
                            }
                        }
                        &.parent {
                            padding: 5px 30px;
                            box-shadow: 0 -1px 0 0 rgba(216, 216, 216, 1),
                                0 1px 0 0 rgba(216, 216, 216, 1);
                            i {
                                padding-right: 0px;
                            }
                            span {
                                // padding-left: 0px;
                                font-weight: 800;
                            }
                        }
                        &.group {
                            padding: 5px 30px 5px 30px;
                            &:after {
                                content: '';
                                position: absolute;
                                top: 5px;
                                bottom: -5px;
                                right: 15px;
                                display: inline-block;
                                background-color: rgba(137, 134, 202, 0.5);
                                width: 2px;
                            }
                            .right-side {
                            }
                            &.last-group {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                        &:first-child {
                            &.parent {
                                box-shadow: 0 1px 0 0 rgba(216, 216, 216, 1);
                            }
                        }
                        &.parent + .parent {
                            box-shadow: 0 1px 0 0 rgba(216, 216, 216, 1);
                        }
                    }
                    .collapse-btns {
                        margin-top: 10px;
                        width: 100%;
                        position: absolute;
                        bottom: 10px;
                        text-align: center;
                        button {
                            margin: 0 auto;
                            background-color: #a1bcdb;
                            font-weight: bold;
                            font-size: 12px;
                            padding: 2px 18px;
                            border-radius: 2px;
                        }
                        // .hider{
                        //     display: none;
                        // }
                        i {
                            position: relative;
                            top: 2px;
                        }
                    }
                    // .collapse.in + .collapse-btns{
                    //     .shower{
                    //         display: none;
                    //     }
                    //     .hider{
                    //         display: inline-block;
                    //     }
                    // }
                }
            }
        }
    }
}
