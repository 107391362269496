table.table {
    thead {
        background: #ffffff;
        box-shadow: inset 0 2px 0 0 #e5e4ea;
    }

    th {
        font-size: 11px;
        font-weight: 500;
        color: #89889b;
        border-bottom: 0;
        font-family: Lato;
    }

    td {
        font-family: Lato;
        border-top: 0;
        font-size: 14px;
    }

    tr {
        &.table-link {
            &:hover {
                // cursor: pointer;
                // filter: brightness(160%);
                // background-color: map-get($theme-colors, 'primary');
            }
        }
    }
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #efeff5;
}

table.benefit-limits-table {
    thead {
    }
    tbody {
        tr {
            &:hover {
                opacity: 0.7;
            }
            &.top {
                background-color: #efeff5;
                & + .top {
                    border-top: 2px solid #ffffff;
                }
                td {
                    font-weight: 500;
                }
            }
            &.level1 {
                background-color: #ffffff;
                td:first-child {
                    padding-left: 20px !important;
                }
                & + .level1 {
                    border-top: 2px solid #efeff5;
                }
            }
            &.level2 {
                background-color: #efeff5;
                td:first-child {
                    padding-left: 40px !important;
                }
                & + .level2 {
                    border-top: 2px solid #ffffff;
                }
                & + .top {
                    border-top: 2px solid #ffffff;
                }
            }
            &.level3 {
                background-color: #ffffff;
                td:first-child {
                    padding-left: 60px !important;
                }
                & + .level3 {
                    border-top: 2px solid #efeff5;
                }
            }

            td {
                &:nth-of-type(2) {
                    color: #429f33;
                }
            }
        }
    }
}
