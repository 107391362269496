// Custom theming
.btn {
    padding: 0.5rem 1.5rem;
}

.btn-link-primary-dark-1 {
    font-family: Lato;
    font-size: 13px;
    color: map-get($theme-colors, 'primary-dark-1');
}

.btn-link-secondary {
    font-family: Lato;
    font-size: 13px;
    color: map-get($theme-colors, 'secondary');
}

.btn-link-outline-gray {
    font-family: Lato;
    font-size: 13px;
    color: map-get($theme-colors, 'primary-dark-1');
    border: 2px solid #d0cfdd;
}

.btn-new-claim {
    font-family: Lato;
    font-size: 13px;
    background-color: map-get($theme-colors, 'tertiary');
}

.btn-outline-gray {
    &:hover {
        background-color: #efefef;
    }

    border: 2px solid #d0cfdd;
    color: #4a4a4a;
}

.btn-link {
    font-weight: 600;
}
