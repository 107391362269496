.overview-ad-block {
    .text {
        padding: 30px 10px 0px 60px;
        p {
            font-size: 29px;
            font-weight: 900;
            line-height: 32px;
            color: #ffffff;
            strong {
                font-weight: 900;
                color: #fff;
            }
        }
        &.promo {
            p {
                strong {
                    color: #fff;
                }
            }
            small {
                color: #fff;
                font-weight: 600;
                font-size: small;
            }
        }
        p + p {
            font-size: 18px;
        }
    }
    .text-subtitle {
        padding-top: 20 0 0 20;
        p {
            font-size: 21px;
            font-weight: 900;
            line-height: 32px;
            color: #ffffff;
            strong {
                font-weight: 900;
                color: #fff;
            }
        }
        p + p {
            font-size: 18px;
        }
    }
    .button-holder {
        margin-top: 20px;
        padding: 0px 0px 20px 60px;
    }
}

.ad-container {
    .overview-ad-block:not(:first-child) {
        margin-top: 20px;
    }
}
