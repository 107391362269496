.payment-amount {
    .tab-content {
        padding: 15px;
        box-shadow: inset 0px -2px 0px 0px #e6e6ee;

        .form-group {
            .dollar-sign {
                width: 5%;
                font-size: 26px;
                text-align: right;
                font-weight: 400;
                color: #8246af;
            }
            .payment-input {
                width: 89%;
                margin-left: 1%;
                margin-right: 5%;
            }
        }

        .covered-until {
            font-size: 12px;
            span {
                font-size: 15px;
            }
        }
    }
    .invalid-feedback {
        margin-left: 30px;
        margin-top: -15px;
    }
}
