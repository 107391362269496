.rebate-form-modal {
    &.modal {
        display: flex !important;
        align-items: center;
        .modal-dialog {
            max-width: 842px;
            .modal-content {
                .modal-body {
                    padding: 30px;
                    .steps {
                        padding-bottom: 20px;
                        span {
                            display: inline-block;
                            height: 26px;
                            width: 26px;
                            border-radius: 50%;
                            text-align: center;
                            font-size: 14px;
                            font-weight: bold;
                            background: #e6e6ed;
                            color: #464b4e;
                            line-height: 26px;
                            margin: 0px 5px;
                            &.active {
                                background: #8246af;
                                color: #ffffff;
                            }
                            &:hover {
                                cursor: pointer;
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }
    }
    &.rebate-form-step-1,
    &.rebate-form-step-2,
    &.rebate-form-step-3,
    &.rebate-form-step-4,
    &.rebate-form-step-5,
    &.rebate-form-step-6 {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    display: none;
                }
            }
        }
    }

    &.rebate-form-step-1 {
        .modal-dialog {
            .modal-content {
                .modal-body {
                }
            }
        }
    }
    &.rebate-form-step-2 {
        .modal-dialog {
            .modal-content {
                .modal-body {
                }
            }
        }
    }
    &.rebate-form-step-3 {
        .modal-dialog {
            max-width: 75vw;
            min-width: 842px;
            width: 100%;
            .modal-content {
                .modal-body {
                }
            }
        }
    }
    &.rebate-form-step-4 {
        .modal-dialog {
            max-width: 75vw;
            min-width: 842px;
            width: 100%;
            .modal-content {
                .modal-body {
                }
            }
        }
    }
    &.rebate-form-step-5 {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    background-color: transparent !important;
                    border-bottom: 0px;
                    padding-bottom: 0px;
                    button {
                        background-color: transparent !important;
                        padding: 0px;
                        svg {
                            color: map-get(
                                $theme-colors,
                                'primary-dark-1'
                            ) !important;
                        }
                    }
                }
                .modal-body {
                    padding-top: 0px;
                    div.row {
                        & > div:nth-of-type(2) {
                            color: map-get(
                                $theme-colors,
                                'primary-dark-1'
                            ) !important;
                            font-size: 38px;
                            font-weight: 900;
                        }
                    }
                }
            }
        }
    }
}
