.fill-height {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

html,
body,
div#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Lato';
}

.form-label {
    font-size: 12px;
    font-weight: 600;
}

.card {
    border: 0;
    display: block;
    background: #ffffff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

button,
.btn {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
