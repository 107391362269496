// Render fonts correctly
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.line-height-1 {
    line-height: 1;
}

.font-feature {
    font-family: Lato, serif;
}

// Font Faces
@include font-face(Lato, '../fonts/Lato-Regular', null, ttf);
@include font-face(Lato, '../fonts/Lato-Thin', 100, ttf);
@include font-face(Lato, '../fonts/Lato-Light', 300, ttf);
@include font-face(Lato, '../fonts/Lato-Bold', 700, ttf);
@include font-face(Lato, '../fonts/Lato-Black', 800, ttf);

// HTML Components
h1 {
    color: map-get($theme-colors, 'primary-dark-1');
    font-size: 21px;
    font-family: Lato, serif;
    font-weight: 500;
}

p,
ul {
    color: #464b4e;
    font-family: Lato, serif;
}

a {
    color: map-get($theme-colors, 'primary-dark-1');
    font-weight: bold;
    text-decoration: underline;
    &:hover,
    &:focus {
        color: map-get($theme-colors, 'primary-dark-1');
        opacity: 0.8;
    }
    &.btn,
    &.nav-link {
        text-decoration: none;
    }
}
.link-none {
    text-decoration: none;
    font-weight: normal;
}

// All other font weights (regular, bold, normal, light are already available from Bootstrap. Don't write them here!
.font-weight-thin {
    font-weight: 100;
}

.font-weight-normal {
    font-weight: 400;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-black {
    font-weight: 800;
}

.font-weight-ultra {
    font-weight: 900;
}

.font-weight-bold {
    font-weight: 700;
}

.font-weight-semibold {
    font-weight: 600;
}
